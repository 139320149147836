import React from 'react'
import { CMSWrapper } from '../../components/cmsWrapper'
import { Link } from 'gatsby'
import { Navigation } from '../../common/navigation'
import { GlobalHelmet } from '../../common/seo/globalHelmet'
import { Footer } from '../../common/footer'

export default function BereitFuerDenSprung() {
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - Bereit für den Sprung" />
      <main>
        <Navigation />

        <header className="bg-img-yellow divider-left">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/marc-oliver-henk-1.jpg"
                  alt="Portrait"
                  title="Marc-Oliver Henk"
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-md-5 offset-md-0 col-10 offset-1">
              <h1>Bereit für den Sprung</h1>
              <h4>
                Marc-Oliver Henk, 49, ist examinierter Altenpfleger und arbeitet
                seit Februar 2020 auf der Station 4D des Kopf- und Neurozentrums
                am UKE.
              </h4>
            </div>
          </div>
        </header>

        <section className="bg-blue divider-right">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Bin ich nicht schon zu alt für einen Neueinstieg? Ein guter
                Bekannter, der am UKE arbeitet, hat meine Bedenken zerstreut:
                ‚Mensch, bewirb dich doch einfach!‘
              </p>
              <p>
                Mein Berufsweg enthält einige Richtungswechsel: zuerst einige
                Jahre Profimusiker mit eigener Band, mit Konzerten und
                Plattenaufnahmen, eine tolle Zeit; ab Mitte der 90er dann
                freiberuflicher Webdesigner, auch spannend und einträglich. Aber
                irgendwann wurde es mir am Rechner zu anonym, ich wollte direkt
                mit – und für – Menschen arbeiten. 2012 habe ich beschlossen, in
                die Altenpflege zu gehen. Dies war für mich eine
                richtungsweisende 180-Grad-Wende.
              </p>
              <p>
                Eine gewisse Vorerfahrung brachte ich mit: Den Zivildienst hatte
                ich in einem Alten- und Pflegeheim absolviert. Außerdem
                arbeitete meine Mutter als Krankenschwester, zwei Tanten und ein
                Onkel waren in der Altenpflege, da erfährt man schon einiges.
                Aber dass mich dieser Beruf auch persönlich so sehr
                weiterbringen würde, hätte ich nie gedacht. Im geschützten
                Wohnbereich für Demenzkranke habe ich erfahren, wie viel
                Potenzial in der Zusammenarbeit mit den Betroffenen steckt. Ich
                habe mich zur Fachkraft für Gerontopsychiatrie und zur
                verantwortlichen Pflegefachkraft weiterentwickelt, aber dann
                doch das starke Bedürfnis verspürt, noch einmal den Sprung zu
                riskieren und mich im UKE zu bewerben. Es war die beste
                berufliche Entscheidung meines Lebens.
              </p>
            </div>
            <div className="col-md-10 offset-md-1 col-12 my-5 text-center">
              <div className="image-container image-container--static image-container--frame">
                <img
                  src="/img/altenpflege/marc-oliver-henk-2.jpg"
                  alt="Portrait"
                  title="Marc Oliver Henk"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="col-md-10 offset-md-1 col-12">
              <p>
                Einen Tag lang habe ich auf der Neurologie hospitiert, danach
                wusste ich: Das ist genau das, was du machen willst. Im Februar
                2020 habe ich auf der{' '}
                <Link to="/station/neurologie-4d">
                  Station 4D des Kopf- und Neurozentrums
                </Link>{' '}
                angefangen, wo Patienten mit unterschiedlichen neurologischen
                Erkrankungen wie z.B. Morbus Parkinson und auch ältere,
                dementiell erkrankte Menschen untersucht und behandelt werden.
                Es passte perfekt. Ins kalte Wasser brauchte ich nicht zu
                springen, eher war es ein sanftes Eintauchen und Gewöhnen. Das
                Team hat mich mit Ruhe und Geduld unterstützt. Vor allem war ich
                sehr dankbar, dass das Team mich auch mit meiner Ausbildung zum
                Altenpfleger direkt akzeptiert hat. Denn bisher würde ich mich
                ja mit meiner Ausbildung im UKE schon noch als „Exot“
                bezeichnen. Zusätzlich interessiere ich mich für Fortbildungen
                rund um den Themenbereich Pflege in der Akademie für Bildung und
                Karriere.
              </p>
              <p>
                Das Zusammenspiel der verschiedenen fachlichen und
                therapeutischen Disziplinen ist faszinierend und bereichernd,
                und ich freue mich auf meine Arbeit, wenn ich von Eimsbüttel
                nach Eppendorf radele.
              </p>
              <p>
                Meine Eingangsfrage ist längst beantwortet: Man ist nie zu alt,
                wenn man Lust aufs Lernen hat und bereit ist, sich auf Neues
                einzulassen.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </CMSWrapper>
  )
}
